const state = {
    aboutCount: 1,
}

const mutations = {
    mutationsAboutCount: (state, data) => {
        state.aboutCount = data;
    },
}

const actions = {
    actionsAboutCountAdd: ({ commit }, data) => {
        commit('mutationsAboutCount', data);
    },
}

export default {
    state, mutations, actions, namespaced: true,
}