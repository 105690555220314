const state = {
    registerCount: 1,
    registerPageOne: {},
    registerPageTwo: {},
    registerPageThree: {},
}

const mutations = {
    mutationsRegisterCount: (state, data) => {
        state.registerCount = data;
    },
    mutationsRegisterPageOne: (state, data) => {
        state.registerPageOne = data;
    },
    mutationsRegisterPageTwo: (state, data) => {
        state.registerPageTwo = data;
    },
    mutationsRegisterPageThree: (state, data) => {
        state.registerPageThree = data;
    },
}

const actions = {
    actionsRegisterCountAdd: ({ commit }, data) => {
        commit('mutationsRegisterCount', data);
    },

    actionsRegisterPageOneAdd: ({ commit }, data) => {
        commit('mutationsRegisterPageOne', data);
    },

    actionsRegisterPageTwoAdd: ({ commit }, data) => {
        commit('mutationsRegisterPageTwo', data);
    },

    actionsRegisterPageThreeAdd: ({ commit }, data) => {
        commit('mutationsRegisterPageThree', data);
    },
}

export default {
    state, mutations, actions, namespaced: true,
}