<template>
  <div>
    <div class="footer">
      <div class="container flex flex-wrap pt-12 pb-12">
        <div class="xl:w-4/12 lg:w-1/2 md:w-1/2 sm:w-full md:border-r-2 lg:border-r-2">
          <div class="content1_1">กองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม</div>
          <div class="content1_2">
            สำนักงานคณะกรรมการดิจิทัลเพื่อเศรษฐกิจและสังคมแห่งชาติ
          </div>
          <div class="content1_3">
            <div>120 หมู่ 3 ชั้น 9 อาคารรัฐประศาสนภักดี</div>
            <div>ศูนย์ราชการเฉลิมพระเกียรติ 80 พรรษา 5 ธันวาคม 2550</div>
            <div>ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง เขตหลักสี่ กรุงเทพฯ 10210</div>
          </div>
          <div>
            <div class="flex flex-row mt-9 ">
              <div class="icon flex items-center" v-for="(item, index) in images" :key="index">
                <img
                  :src="`${item}`"
                  class="object-contain"
                  style="width: 15px; height: 15px"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="pr-0 xl:pr-16 sm:hidden md:hidden lg:block">
          <div class="w-1 border-r-2 h-full"></div>
        </div> -->

        <div class="xl:w-5/12 lg:w-1/2 md:w-1/2 sm:w-full xl:border-r-2 md:pl-4 ">
          <!-- <div class="flex flex-wrap lg:pl-4">
            <div
              class="mb-3 md:w-full lg:w-1/2 w-full truncate"
              v-for="(item, index) in items"
              :key="index"
            >
              {{ item }}
            </div>
          </div> -->
          <div class="grid sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-3 xl:pl-12">
            <div class="col-span-1 mt-1" style="margin-bottom:10px;">หน้าแรก</div>
            <div class="col-span-2" style="margin-bottom:10px;">Q&A งานกองทุนฯ</div>
            <div class="col-span-1" style="margin-bottom:10px;">e-Newsletter</div>
            <div class="col-span-2" style="margin-bottom:10px;">ดาวน์โหลดเอกสาร</div>
            <div class="col-span-1" style="margin-bottom:10px;">ข่าวสารประชาสัมพันธ์</div>
            <div class="col-span-2" style="margin-bottom:10px;">แบบสำรวจความพึงพอใจการให้บริการ</div>
            <div class="col-span-1" style="margin-bottom:10px;">ปฏิทินกิจกรรม</div>
          </div>
        </div>

        <!-- <div class="pr-0 xl:pr-16 sm:hidden md:hidden lg:block">
          <div class="w-1 border-r-2 h-full"></div>
        </div> -->

        <div class="xl:w-3/12 lg:w-1/2 md:w-1/2 w-full lg:pl-4 xl:pl-12">
          <div class="">
            <div class="w-1 border-r-2 h-full"></div>
          </div>
          <div style="margin-top: 35px;">
            <div class="content3 pb-2" style="font-size: 16px">ติดต่อเรา</div>
            <div class="relative">
              <span class="absolute inset-y-0 left-0 pl-2 flex items-center">
                <button
                  type="submit"
                  class="py-2 px-3 pl-1 focus:outline-none focus:shadow-outline"
                >
                  <font-awesome-icon
                    icon="envelope"
                    color="rgba(255, 255, 255, 0.59)"
                  />
                </button>
              </span>
              <input
                class="placeholder py-2 px-3 pl-10 w-full rounded-md focus:rounded-md focus-within:outline-none pl-2 pr-2"
                style="
                  background: rgba(199, 179, 232, 0.3);
                  font-size: 18px;
                "
                placeholder="อีเมล"
                type="email"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="subFooter flex items-center justify-items-center justify-center"
    >
      <div class="container flex items-center justify-items-center justify-center">
        สงวนลิขสิทธิ์ © 2560
      สำนักงานคณะกรรมการดิจิทัลเพื่อเศรษฐกิจและสังคมแห่งชาติ
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import phone from '@/assets/icon/phone.svg';
import email from '@/assets/icon/email.svg';
import facebook from '@/assets/icon/facebook.svg';
import twitter from '@/assets/icon/twitter.svg';
export default {
  setup() {
    let items = ref([
      "หน้าแรก",
      "Q&A งานกองทุนฯ",
      "e-Newsletter",
      "ดาวน์โหลดเอกสาร",
      "ข่าวสารประชาสัมพันธ์",
      "แบบสำรวจความพึงพอใจการให้บริการ",
      "ปฏิทินกิจกรรม",
    ]);
    let images = ref([
        phone,
        email,
        facebook,
        twitter,
    ]);
    return { items, images };
  },
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

::placeholder {
  color: rgba(255, 255, 255, 0.59);
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: rgba(255, 255, 255, 0.59);
}

::-ms-input-placeholder { /* Microsoft Edge */
 color: rgba(255, 255, 255, 0.59);
}

.footer {
  color: white;
  background-color: #573c81;
}

.subFooter {
  font-style: normal;
  font-weight: normal;
  font-family: KanitFont;
  font-size: 14px;
  line-height: 21px;
  height: 52px;
  color: white;
  background: #462b6e;
}

.content1_1 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 5px;
}

.content1_2 {
  padding-bottom: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 27px;
}

.content1_3 {
  font-family: KanitFont;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.icon {
  /* margin: 8px; */
  margin-right: 8px;
  margin-bottom: 8px;
  margin-top: 30px;
  width: 32px;
  height: 32px;
  left: 390px;
  top: 4436px;
  border-radius: 100%;
  display: flex;
  justify-content: center;

  border: 1px solid #ffffff;
  box-sizing: border-box;
}

</style>