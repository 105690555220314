import { createStore } from "vuex" 
import register from './auth/register';
import about from './about/about';
import request from "./request/request";

const store = createStore({
   modules: {
     register,
     about,
     request,
   }
})

export default store;