const state = {
    requestCount: 4,
}

const mutations = {
    mutationsRequestCount: (state, data) => {
        state.requestCount = data;
    },
}

const actions = {
    actionRequestCountAdd: ({ commit }, data) => {
        commit('mutationsRequestCount', data);
    },
}

export default {
    state, mutations, actions, namespaced: true,
}