<template>
  <header>
    <div v-if="token !== null">
      <navigationMember />
    </div>

    <div v-else>
      <navigationGuest />
    </div>

    <div class="ptNavbar"></div>

    <main class="" style="height: 100%">
      <!-- <ThailandAutoComplete v-model="zipcode" type="zipcode" @select="select" size="large" color="#00a4e4" placeholder="รหัสไปรษณีย์..."/>  -->
      <router-view />
    </main>

    <footer>
      <footerComponent />
    </footer>
  </header>
</template>

<script>
import navigationGuest from "@/components/navigation/navigationGuest.vue";
import navigationMember from "@/components/navigation/navigationMember.vue";
import footerComponent from "@/components/footer/footerComponent.vue";
export default {
  components: {
    navigationGuest,
    navigationMember,
    footerComponent,
  },
  methods: {
    select (address) {
      // this.district = address.district
      // this.amphoe = address.amphoe
      // this.province = address.province
      this.zipcode = address.zipcode
    }
  },
  data() {
    return {
      name: null,
      token: localStorage.getItem("@auth"),
      zipcode: '',
    };
  },
};
</script>

<style>
@font-face {
  font-family: KanitFont;
  src: url(./assets/font/Kanit/Kanit-Light.ttf);
}

.flexContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ptNavbar {
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(36, 36, 36, 0.15);
  color: #573c81;
}

.swal2-styled.swal2-confirm {
  background-color: #573c81 !important;
  border-radius: 5px !important;
  font-family: KanitFont !important;
  font-style: normal !important;
  font-weight: normal !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.swal2-container.swal2-center > .swal2-popup {
  flex-direction: column !important;
}

.swal2-title {
  font-family: KanitFont !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 32px !important;
  color: #000000 !important;
  /* margin-bottom: 50px !important; */
}

.validate {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #fa4238;
}

@media (max-width: 768px) {
  .ptNavbar {
    height: 70px;
  }
}

@media (min-width: 768px) {
  .ptNavbar {
    height: 0px;
  }
}

/* @media (min-width: 1440px) {
  .container {
    left: 0;
    right: 0;
  }   
} */

</style>




