import axios from 'axios';

const token = localStorage.getItem("@auth");

let config_token = {
    headers: {
        'Authorization': 'Bearer ' + token
    }
}

const guest = async ({ to, next }) => {
    if (to.name === 'login') {
        if (token !== null) {
            location.href = '/';
        }
    } else if (to.name === 'register') {
        if (token !== null) {
            location.href = '/';
        }
    } else if (to.name === 'forgotPassword') {
        if (token !== null) {
            location.href = '/';
        }
    } else if (to.name === 'changePassword') {
        if (token !== null) {
            location.href = '/';
        }
    } else {
        axios.get(`${process.env.VUE_APP_URL_API}/users/profile`, config_token).then(response => {
            localStorage.setItem('_id', response.data.data._id);
            localStorage.setItem('profile', JSON.stringify(response.data.data));
        }).catch(error => {
            if (error.response.status === 401) {
                localStorage.removeItem('@auth');
                localStorage.removeItem('_id');
                location.href = '/login';
            }
        })
    }
    return next();
}

const member = ({to, next}) => {
    axios.get(`${process.env.VUE_APP_URL_API}/users/profile`, config_token).then(response => {
        localStorage.setItem('_id', response.data.data._id);
        if (to.name === 'admin') {
            location.href="/";
        }
    }).catch(error => {
        if (error.response.status === 401) {
            localStorage.removeItem('@auth');
            localStorage.removeItem('_id');
            location.href = '/login';
        }
    })
    return next();
}

export default {
    guest,
    member,
}

// export default ({ to, next }) => {
//     axios.get(`${process.env.VUE_APP_URL_API}/users/profile`, config_token).then(response => {
//         localStorage.setItem('_id', response.data.data._id);
//         // if (to.name === "login") {
//         //     console.log('w')
//         // }
//         if (response.data.data.status === 'member') {

//         } else if (response.data.status === 'admin') {

//         }
//     }).catch(error => {
//         if (error.response.status === 401) {
//             // console.log('asd')
//             // router.push({name: 'login'});
//             // location.href="/login";
//         }
//     });
//     next();
// }