import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import VCalendar from 'v-calendar';
import router from './router';
import store from "./store/index";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone, faEnvelope, faSearch, faLongArrowAltRight, faBars, faEye, faEyeSlash, faCalendarAlt, faPlus, faMinus, faCheck } from "@fortawesome/free-solid-svg-icons";
library.add(faPhone, faEnvelope, faSearch, faLongArrowAltRight, faBars, faEye, faEyeSlash, faCalendarAlt, faPlus, faMinus, faCheck);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import ThailandAutoComplete from 'vue-thailand-address-autocomplete'

import middleware from "@grafikri/vue-middleware"

router.beforeEach(middleware());

createApp(App).component("font-awesome-icon", FontAwesomeIcon).component('ThailandAutoComplete', ThailandAutoComplete).use(VueSweetalert2).use(VCalendar, {}).use(router).use(store).mount('#app')
