import { createRouter, createWebHistory, } from "vue-router";
import authentication from '../middleware/authentication';

const routes = [
    {
        path: "/",
        name: "welcome",
        component: () => import('@/views/welcome.vue'),
    },
    {
        path: "/home",
        name: "home",
        component: () => import('@/views/home.vue'),
        // meta: {
        //   middleware: [authentication.guest]
        // }
    },
    {
        path: "/login",
        name: "login",
        component: () => import('@/views/login.vue'),
        meta: {
            middleware: [authentication.guest]
        },
    },
    {
        path: "/forgotpassword",
        name: "forgotPassword",
        component: () => import('@/views/forgotPassword.vue'),
        meta: {
            middleware: [authentication.guest]
        },
    },
    {
        path: "/changepassword/:id",
        name: "changePassword",
        component: () => import('@/views/changePassword.vue'),
        meta: {
            middleware: [authentication.guest]
        },
    },
    {
        path: "/register",
        name: "register",
        component: () => import('@/views/register.vue'),
        meta: {
            middleware: [authentication.guest]
        },
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about.vue')
    },
    {
        path: '/request',
        name: 'request',
        component: () => import('@/views/request.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('@/views/news.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/contact.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('@/views/news.vue')
    },
    {
        path: '/news/:id',
        name: 'newsId',
        component: () => import('@/components/newsId/newsIdComponent.vue')
    },
    {
        path: '/activity',
        name: 'activity',
        component: () => import('@/views/activity.vue')
    },
    {
        path: '/activity/:id',
        name: 'activityId',
        component: () => import('@/components/activityId/activityId.vue')
    },
    {
        path: '/knowledge/video',
        name: 'knowledgeVideo',
        component: () => import('@/components/knowledge/knowledgeVideo.vue')
    },
    {
        path: '/knowledge/photo',
        name: 'knowledgePhoto',
        component: () => import('@/components/knowledge/knowledgePhoto.vue')
    },
    {
        path: '/knowledge/data',
        name: 'knowledgeData',
        component: () => import('@/components/knowledge/knowledgeData.vue')
    },
    {
        path: '/knowledge/open',
        name: 'knowledgeOpen',
        component: () => import('@/components/knowledge/knowledgeOpen.vue')
    },
    {
        path: '/knowledge/process',
        name: 'knowledgeProcess',
        component: () => import('@/components/knowledge/knowledgeProcess.vue')
    },
    {
        path: '/capital',
        name: 'capital',
        component: () => import('@/views/capital.vue'),
        meta: {
            middleware: [authentication.guest]
        },
    },
    {
        path: '/capital/create',
        name: 'capitalCreate',
        component: () => import('@/components/capital/createComponent.vue'),
        meta: {
            middleware: [authentication.guest]
        },
    },
    {
        path: '/test/1',
        name: 'capitalPage1',
        component: () => import('@/components/capital/page1Component.vue'),
        meta: {
            middleware: [authentication.guest]
        },
    },
    {
        path: '/test/2',
        name: 'capitalPage2',
        component: () => import('@/components/capital/page2Component.vue'),
        meta: {
            middleware: [authentication.guest]
        },
    },
    {
        path: '/test/3',
        name: 'capitalPage3',
        component: () => import('@/components/capital/page3Component.vue'),
        meta: {
            middleware: [authentication.guest]
        },
    },
    {
        path: '/test/4',
        name: 'capitalPage4',
        component: () => import('@/components/capital/page4Component.vue'),
        meta: {
            middleware: [authentication.guest]
        },
    },
    {
        path: '/test/5',
        name: 'capitalPage5',
        component: () => import('@/components/capital/page5Component.vue'),
        meta: {
            middleware: [authentication.guest]
        },
    },
    {
        path: '/test/6',
        name: 'capitalPag6',
        component: () => import('@/components/capital/page6Component.vue'),
        meta: {
            middleware: [authentication.guest]
        },
    },
    {
        path: '/test/8',
        name: 'capitalPag8',
        component: () => import('@/components/capital/page8Component.vue'),
        meta: {
            middleware: [authentication.guest]
        },
    },
    {
        path: '/capital/:id',
        name: 'capitalPage',
        component: () => import('@/components/capital/page9Component.vue'),
        meta: {
            middleware: [authentication.guest]
        },
    },
];

const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes,
});

// router.beforeEach((to, from, next) => {
//   const token = localStorage.getItem("@auth");
//   let config_token = {
//     headers: {
//       'Authorization': 'Bearer ' + token
//     }
//   }

//   if (to.name === 'login') {
//     if (token !== null) {
//       location.href = '/';
//     }
//   } else if (to.name === 'register') {
//     if (token !== null) {
//       location.href = '/';
//     }
//   } else if (to.name === 'forgotPassword') {
//     if (token !== null) {
//       location.href = '/';
//     }
//   } else if (to.name === 'changePassword') {
//     if (token !== null) {
//       location.href = '/';
//     }
//   } else {
//     axios.get(`${process.env.VUE_APP_URL_API}/users/profile`, config_token).then(response => {
//       localStorage.setItem('_id', response.data.data._id);
//     }).catch(error => {
//       if (error.response.status === 401) {
//         localStorage.removeItem('@auth');
//         // location.href = '/login';
//       }
//     })
//   }
//   return next();
// });

export default router;