<template>
  <nav class="navigation fixed z-50 md:z-0" id="navigationSm">
    <div class="container">
      <div class="flex flex-row">
        <div class="w-1/2 text-left">
          <router-link to="/" @click="checkNavigationSlide('/')">
            <img
              class="image object-contain"
              src="@/assets/mog/logo.png"
              style="width: 84px; height: 37px"
            />
          </router-link>
        </div>
        <div class="w-1/2 flex items-center justify-end">
          <button @click="checkNavigation()">
            <font-awesome-icon color="black" icon="bars" />
          </button>
        </div>
      </div>
    </div>
  </nav>

  <nav class="navigation fixed z-50" id="showNavigation">
    <div class="w-full">
      <div class="flex justify-start" style="height: 69px; margin-left: 16px">
        <button @click="checkNavigation()">
          <font-awesome-icon color="black" icon="bars" />
        </button>
      </div>
      <div class="w-full grid grid-cols-1 text-left">
        <div v-for="(item, index) in items" :key="index">
          <div v-if="item.link !== '/login'">
            <div
              class="navigationSlide"
              :id="`navigationSlideId${index}`"
              @click="checkNavigationSlide(`${item.link}`)"
            >
              <div style="margin-left: 16px">
                {{ item.name }}
              </div>
              <!-- <router-link
              style="margin-left: 16px"
              :to="`${item.link}`"
            >
              {{ item.name }}
            </router-link> -->
            </div>
          </div>
          <div
            style="margin-left: 16px; margin-right: 16px"
            class="navigationSlide"
            v-else
          >
            <router-link :to="`${item.link}`">
              <button
                @click="checkNavigationSlide('/login')"
                class="w-full"
                style="
                  background: #573c81;
                  border-radius: 5px;
                  color: white;
                  height: 50px;
                  width: 100%;
                  max-width: 767%;
                "
              >
                {{ item.name }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <nav class="navigation" id="navigationMd">
    <div
      class="container flex items-center justify-items-center justify-center"
    >
      <div class="">
        <router-link to="/" @click="checkColor('/')">
          <img
            class="image object-contain"
            src="@/assets/mog/logo.png"
            alt=""
          />
        </router-link>
      </div>

      <div class="w-full flex flex-wrap truncate">
        <router-link
          to="/"
          class="lg:w-1/6 md:w-1/6"
          id="myPath0"
          @click="checkColor('/')"
        >
          <button
            class="focus-within:outline-none"
            id="btn0"
            style="height: 120px"
          >
            หน้าแรก
          </button>
        </router-link>

        <router-link
          to="/about"
          class="lg:w-1/6 md:w-1/6"
          id="myPath1"
          @click="checkColor('/about')"
        >
          <div class="dropdown">
            <button
              class="focus-within:outline-none"
              id="btn1"
              style="height: 120px"
            >
              เกี่ยวกับเรา
            </button>

            <div class="dropdown-content">
              <p @click="addAbout(1)" id="aboutMD1">เกี่ยวกับกองทุน</p>
              <p @click="addAbout(2)" id="aboutMD2">บทบาทของกองทุน</p>
              <p @click="addAbout(3)" id="aboutMD3">
                วิสัยทัศน์ พันธกิจ และเป้าหมาย
              </p>
              <p @click="addAbout(4)" id="aboutMD4">
                วัตถุประสงค์การใช้จ่ายเงินของกองทุน
              </p>
              <p @click="addAbout(5)" id="aboutMD5">โครงสร้างบุคลากร</p>
            </div>
          </div>
        </router-link>

        <router-link
          to="/request"
          class="lg:w-1/6 md:w-1/6"
          id="myPath2"
          @click="checkColor('/request')"
        >
          <button
            class="focus-within:outline-none"
            id="btn2"
            style="height: 120px"
          >
            การขอรับทุน
          </button>
        </router-link>

        <router-link
          to="/news"
          class="lg:w-1/6 md:w-1/6"
          id="myPath3"
          @click="checkColor('/news')"
        >
          <button
            class="focus-within:outline-none"
            id="btn3"
            style="height: 120px"
          >
            ข่าวสารและกิจกรรม
          </button>
        </router-link>

        <router-link
          to="/contact"
          class="lg:w-1/6 md:w-1/6"
          id="myPath4"
          @click="checkColor('/contact')"
        >
          <button
            class="focus-within:outline-none"
            id="btn4"
            style="height: 120px"
          >
            ติดต่อเรา
          </button>
        </router-link>

        <div class="lg:w-1/6 md:w-1/6 flex items-center">
          <router-link class="w-full" to="/login" @click="checkColor('/login')">
            <button
              class="focus-within:outline-none"
              style="
                background-color: #573c81;
                color: white;
                width: 100%;
                height: 50px;
                border-radius: 5px;
              "
            >
              เข้าสู่ระบบ
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ref } from "vue";
export default {
  mounted() {
    this.checkColor(location.pathname);
    this.checkNavigationSlide(location.pathname);
  },
  computed: {
    ...mapState({
      aboutCount: (state) => state.about.aboutCount,
    }),
  },
  methods: {
    ...mapActions("about", ["actionsAboutCountAdd"]),
    addAbout(id) {
      this.actionsAboutCountAdd(id);
      // try {
      //   for (let i = 0; i !== 5; i++) {
      //     let aboutMD = document.getElementById(`aboutMD${i + 1}`);
      //     if (id === i + 1) {
      //       aboutMD.style.background = "#fa4238";
      //       aboutMD.style.color = "white";
      //       aboutMD.style.textAlign = "left";
      //       aboutMD.style.borderLeftWidth = "4px";
      //       aboutMD.style.borderLeftColor = "#C7352D";
      //       aboutMD.style.paddingLeft = "11px";
      //       aboutMD.style.paddingTop = "15px";
      //       aboutMD.style.paddingRight = "15px";
      //       aboutMD.style.paddingBottom = "15px";
      //     } else {
      //       aboutMD.style.background = "#f9f9f9";
      //       aboutMD.style.color = "#573C81";
      //       aboutMD.style.padding = "15px";
      //       aboutMD.style.borderLeftWidth = "0px";
      //       aboutMD.style.borderColor = "#C7352D";
      //       aboutMD.classList.add('pHover');

      //       // aboutMD.addEventListener(
      //       //   "mouseenter",
              
      //       //   function (event) {
      //       //     setTimeout(function () {
      //       //       event.target.style.backgroundColor = "#f9f9f9";
      //       //       event.target.style.color = "#573C81";
      //       //       event.target.style.padding = "15px";
      //       //       event.target.style.borderLeftWidth = "0px";
      //       //       event.target.style.borderColor = "white";
      //       //     }, 100000000);
      //       //   },
      //       //   false
      //       // );
      //     }
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
    },
    async checkColor(link) {
      //dashboard
      try {
        for (let i = 0; i !== this.items.length; i++) {
          let myPath = await document.getElementById(`myPath${i}`);

          if (this.items[i].link === link) {
            this.checkNavigationSlide(this.items[i].link);
            this.colorNavigation = link;
            myPath.style.color = "#FA4238";
            myPath.style.border = "solid #C7352D";
            myPath.style.borderLeft = "none";
            myPath.style.borderRight = "none";
            myPath.style.borderBottom = "none";
            document.getElementById(`btn${i}`).classList.add("font-black");
          } else {
            myPath.style.color = "#573c81";
            myPath.style.border = "none";
            // myPath.style.fontWeight = 'normal';
            myPath.style.border = "solid white";
            myPath.style.borderLeft = "none";
            myPath.style.borderRight = "none";
            myPath.style.borderBottom = "none";
            document.getElementById(`btn${i}`).classList.remove("font-black");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkNavigation() {
      //menu
      try {
        let navigationSm = document.getElementById("navigationSm");
        let showNavigation = document.getElementById("showNavigation");
        if (navigationSm.style.display === "none") {
          navigationSm.style.display = "flex";
          showNavigation.style.display = "none";
        } else {
          navigationSm.style.display = "none";
          showNavigation.style.display = "flex";
          showNavigation.style.justifyItems = "center";
          showNavigation.style.justifyContent = "flex-start";
          showNavigation.style.alignItems = "flex-start";
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkNavigationSlide(link) {
      //side
      try {
        for (let i = 0; i !== this.items.length; i++) {
          let navigationSlideId = document.getElementById(
            `navigationSlideId${i}`
          );
          if (this.items[i].link === link) {
            this.$router.push(link);
            navigationSlideId.style.color = "white";
            // navigationSlideId.style.fontWeight = "bold";
            navigationSlideId.style.backgroundColor = "#FA4238";
            navigationSlideId.style.borderLeft = "solid #C7352D";
            navigationSlideId.style.borderLeftWidth = "8px";
            if (this.colorNavigation !== this.items[i].link) {
              this.checkColor(this.items[i].link);
            }
          } else if (link === "/login") {
            this.$router.push(link);
            navigationSlideId.style.backgroundColor = "white";
            navigationSlideId.style.backgroundColor = "white";
            navigationSlideId.style.color = "#573C81";
            navigationSlideId.style.fontWeight = "normal";
            navigationSlideId.style.border = "none";
            navigationSlideId.style.borderLeft = "solid white";
            navigationSlideId.style.borderLeftWidth = "8px";
          } else {
            navigationSlideId.style.backgroundColor = "white";
            navigationSlideId.style.backgroundColor = "white";
            navigationSlideId.style.color = "#573C81";
            navigationSlideId.style.fontWeight = "normal";
            navigationSlideId.style.border = "none";
            navigationSlideId.style.borderLeft = "solid white";
            navigationSlideId.style.borderLeftWidth = "8px";
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  setup() {
    let colorNavigation = ref(null);
    // let slideNavigation = ref(null);
    let items = ref([
      {
        name: "หน้าแรก",
        link: "/",
      },
      {
        name: "เกี่ยวกับเรา",
        link: "/about",
      },
      {
        name: "การขอรับทุน",
        link: "/request",
      },
      {
        name: "ข่าวสารและกิจกรรม",
        link: "/news",
      },
      {
        name: "ติดต่อเรา",
        link: "/contact",
      },
      {
        name: "เข้าสู่ระบบ",
        link: "/login",
      },
    ]);
    return { colorNavigation, items };
  },
};
</script>

<style scoped>
.navigation {
  height: 120px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(36, 36, 36, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: KanitFont;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #573c81;
}

.image {
  /* position: absolute; */
  left: 20.05%;
  right: 70.05%;
  top: 14.17%;
  bottom: 15%;
}

#navigationSm {
  height: 69px;
}

.navigationSlide {
  font-family: KanitFont;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  #navigationMd {
    display: none;
  }
  #showNavigation {
    display: none;
    width: 90%;
    height: 100%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 768px) {
  .image {
    width: 110px;
    height: 49px;
    padding: 0px;
  }
  .justify-center {
    padding: 0px;
  }
  .navigation {
    font-size: 14px;
  }
  .container {
    padding: 16px;
  }
  #navigationSm {
    display: none;
    opacity: 0;
  }
  #showNavigation {
    display: none;
    opacity: 0;
    z-index: -1;
  }
}

@media screen and (min-width: 1024px) {
  .image {
    width: 190.13px;
    height: 85px;
  }
  .navigation {
    font-size: 18px;
  }
  #navigationSm {
    display: none;
    opacity: 0;
    z-index: -2;
  }
  #showNavigation {
    z-index: -2;
  }
}

.dropdown {
  /* position: absolute; */
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown:hover .dropdown-content {
  display: block;
  /* width: 383px;
  height: 266px; */
  z-index: 1;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #573c81;
  margin: 0px;
  text-align: left;
  padding: 15px;
}

p:hover {
  /* left: 1.72%;
  right: -31.62%;
  top: 27.09%;
  bottom: 59.75%; */
  background: #fa4238;
  color: white;
  text-align: left;
  border-left-width: 4px;
  border-left-color: #c7352d;
  padding-left: 11px;
  padding-top: 15;
  padding-right: 15;
  padding-bottom: 15;
}

.pHover {
  background: #fa4238;
}
</style>